<template>
  <div>
    <v-card>
      <v-card>
        <br />
        <v-card-title class="title">
          <v-layout row wrap>
            <v-flex xs12>
              <v-student-search-field
                @onSelectStudent="onSelectStudent"
                :companyId="schoolId"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-if="student && student.details"
                v-model="student.details.admission_date"
                label="Admission Date"
                outlined
              />
            </v-flex>
            <v-flex xs12>
              <edit-button
                :onCurrentBatch="false"
                v-if="student && student.details"
                @agree="updateAdmissionDate"
                :permission="'school-read'"
                :block="true"
                :large="true"
                >Update</edit-button
              >
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-card>

      <br />
    </v-card>
  </div>
</template>

<script>
import Mixins from "@/library/Mixins";
import VStudentSearchField from "../../../../../components/app/VStudentSearchField.vue";
// import Form from "@/library/Form";
export default {
  components: { VStudentSearchField },
  mixins: [Mixins],
  props: ["schoolId"],
  data() {
    return {
      student: {},
    };
  },
  watch: {},
  mounted() {
    // this.fetchStudent(982);
  },
  computed: {},
  methods: {
    onSelectStudent(student) {
      this.student = student;
    },
    updateAdmissionDate() {
      this.$rest
        .post("/api/super/enroll/admission-date", {
          admission_date: this.student.details.admission_date,
          enroll: this.student.enroll_code,
          companyId: this.schoolId,
        })
        .then((res) => {
          this.$events.fire("notification", {
            message: "Admission Date Updated Successfully",
            status: "success",
          });
        });
    },
  },
};
</script>

<style lang="scss"></style>
